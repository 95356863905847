import {initializeAppCheck, ReCaptchaEnterpriseProvider} from "@firebase/app-check"
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {ref} from "vue";
import {Ref} from "@vue/reactivity";
import {LoginRoute, router} from "./router";

const firebaseConfig = {
    apiKey: "AIzaSyBWHTg9Nk8YCaSRXV3kXrOt62FPzQ1THM8",
    authDomain: "andyouarelive.com",
    projectId: "and-you-are-live",
    storageBucket: "and-you-are-live.appspot.com",
    messagingSenderId: "28061025711",
    appId: "1:28061025711:web:0df52bed986cf5accedb5a",
    measurementId: "G-Y346N128PV"
}

export const firebaseApp = firebase.initializeApp(firebaseConfig);
initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider("6LeRHpMpAAAAAJJQ31PDQJ-Ca-VzXIqgVabl6LDO"),
    isTokenAutoRefreshEnabled: true
});

// firebase emulators:start --only auth,firestore
console.log('environment', process.env.NODE_ENV)
const DEBUG = process.env && process.env.NODE_ENV == 'development'

export const auth = firebaseApp.auth()
if (DEBUG) {
    auth.useEmulator("http://127.0.0.1:7000");
}

export const currentUser: Ref<firebase.User | null | undefined> = ref()
auth.onAuthStateChanged(async user => {
    currentUser.value = user
    if (user === null) {
        await router.push(LoginRoute.path)
    }
})

export const waitForUserChange: () => Promise<firebase.User | null> = () => new Promise((resolve) => {
    auth.onAuthStateChanged(resolve);
});

export const firestore = firebaseApp.firestore()
if (DEBUG) {
    firestore.useEmulator("127.0.0.1", 7002);
}

export const firebaseUIAuthContainerId = 'firebase-ui-auth-container';
