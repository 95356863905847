import {createRouter, createWebHistory} from 'vue-router/dist/vue-router';
import Screen from "@/components/Screen.vue";
import {waitForUserChange} from "@/firebaseInit";
import Login from "@/components/Login.vue";

export const LoginRoute = {
    path: "/login",
    name: "Login",
    component: Login
};
export const HomeRoute = {
    path: "/",
    name: "App",
    component: Screen
};
const routes = [
    LoginRoute,
    HomeRoute,
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const user = await waitForUserChange()
    if (to.path !== LoginRoute.path && !user) {
        next(LoginRoute.path)
    } else if (to.path === LoginRoute.path && user && to.query['force'] !== 'true') {
        next(HomeRoute.path)
    } else {
        next()
    }
})

export default router;