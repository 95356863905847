<template>
  <div>
    <el-popover
        placement="bottom"
        title="New Employee"
        width="200"
        trigger="click"
    >
      <el-input
          placeholder="John Doe"
          v-model="name"
          @blur="createEmployee(name, dateString)"
      ></el-input>
      <template #reference>
        <el-button round type="success">Add New Employee</el-button>
      </template>
    </el-popover>
    <el-table :data="filteredEmployees" style="width: 100%;">
      <el-table-column label="Date" prop="date"></el-table-column>
      <el-table-column label="Name" prop="name"></el-table-column>
      <el-table-column align="right">
        <template #header="scope">
          <el-input v-model="search" size="small" placeholder="Type to search" />
        </template>
        <template #default="scope">
          <el-popover
              placement="bottom"
              title="Edit Employee"
              width="200"
              trigger="click"
          >
            <el-input
                placeholder="John Doe"
                v-model="scope.row.name"
                @blur="updateEmployee(scope.row.id, scope.row.name, dateString)"
            ></el-input>
            <template #reference>
              <el-button size="small">Edit</el-button>
            </template>
          </el-popover>
          <el-button
              size="small"
              type="danger"
              @click="deleteEmployee(scope.row.id)"
          >Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup lang="ts">
import {useCollection} from 'vuefire'
import {addDoc, collection, deleteDoc, doc, setDoc, connectFirestoreEmulator} from "firebase/firestore";
import {computed} from "vue";
import {Ref} from "@vue/reactivity";
import {firestore} from "@/firebaseInit";

const employeesPath = "employees";
const employees = useCollection(collection(firestore, employeesPath))

const search: Ref<string | undefined> = defineModel("search")
const name: Ref<string | undefined> = defineModel("name")
const dateString = new Date().toISOString().slice(0, 10)

const filteredEmployees = computed(() => {
  return employees.value.filter(
      (data: {
        name: string;
      }) => !search.value || data.name.toLowerCase().includes(search.value.toLowerCase())
  );
})

const createEmployee = async function (name: string | undefined, date: string) {
  await addDoc(collection(firestore, employeesPath), {date: date, name: name})
}
const updateEmployee = async function (id: string, name: String, date: string) {
  await setDoc(doc(firestore, employeesPath, id), {date: date, name: name})
}
const deleteEmployee = async function (id: string) {
  await deleteDoc(doc(firestore, employeesPath, id))
}
</script>