<template/>

<script setup lang="ts">
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {auth, currentUser, firebaseUIAuthContainerId} from "@/firebaseInit";
import {HomeRoute, router} from "@/router";

let signInOptions = [
  // Leave the lines as is for the providers you want to offer your users.
  // These need to be independently enabled through the web console.
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  // firebase.auth.GithubAuthProvider.PROVIDER_ID,
  firebase.auth.EmailAuthProvider.PROVIDER_ID,
  // firebase.auth.PhoneAuthProvider.PROVIDER_ID
];
if (currentUser.value === null || !currentUser.value?.isAnonymous) {
  signInOptions.push(firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID);
}

const firebaseConfigUI: firebaseui.auth.Config = {
  signInOptions: signInOptions,
  tosUrl: '/tos',
  privacyPolicyUrl: '/privacy',
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      router.push(HomeRoute.path)
      return false
    },
    signInFailure: function (error) {
      console.log('firebaseui.auth.Config.signInFailure', error)
    }
  }
}
const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)
ui.start('#' + firebaseUIAuthContainerId, firebaseConfigUI)
</script>