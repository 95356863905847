<template>
  <div class="vl-parent">
    <loading v-model:active="isLoading" :is-full-page="isFullPage"/>
  </div>
</template>

<script setup lang="ts">
import {waitForUserChange} from "@/firebaseInit";
import {ref} from "vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const isLoading = ref(false)
const isFullPage = ref(true)

const showLoadingAfterMillis = 200;
const beginLoading = setTimeout(() => {
  isLoading.value = true;
}, showLoadingAfterMillis);

waitForUserChange().then(() => {
  clearTimeout(beginLoading)
  isLoading.value = false;
})
</script>