import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element'
import {VueFire, VueFireAuth} from 'vuefire'
import {firebaseApp} from "./firebaseInit";

const app = createApp(App)
app.use(VueFire, {
    firebaseApp: firebaseApp
})
installElementPlus(app)
app.use(router)
app.mount('#app')