<template>
  <nav>
    <div>Navigation</div>
    <div v-if="currentUser && route.path !== LoginRoute.path">
      <div>User: {{ currentUser?.uid }}</div>
      <el-button
          v-if="currentUser && !currentUser?.isAnonymous"
          size="small"
          @click="logOut()"
      >Sign Out
      </el-button>
      <el-button
          v-if="currentUser?.isAnonymous"
          size="small"
          @click="logIn()"
      >Sign In
      </el-button>
    </div>
  </nav>
  <div v-bind:id="firebaseUIAuthContainerId"/><!-- This has to be on every page to cope with log out -->
</template>

<script setup lang="ts">
import {auth, currentUser, firebaseUIAuthContainerId} from "@/firebaseInit";
import {LoginRoute, router} from "@/router";
import {useRoute} from "vue-router";

const logOut = () => {
  auth.signOut()
}
const logIn = () => {
  router.push({path: LoginRoute.path, query: {force: "true"}})
}
const route = useRoute();
</script>

<style scoped>

</style>